import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Routes, Route, Link, json } from "react-router-dom";
import { useEffect, useState } from "react";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
      <Routes>
        <Route path="/about" element={<About />} />
      </Routes>
    </BrowserRouter>
  );
}

function Home() {
  const [rammy, setRammy] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    if (name && description) {
      const data = {
        name: name,
        description: description,
      };

      const option = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify(data),
      };

      // useEffect(() => {
      const postData = async () => {
        const res = await fetch("https://admin.ogitechsug.com.ng/test/test/create", option);
        const data = await res.json();
        // setRammy(data);
        console.log(res, data);
      };

      postData();
      // }, []);
    }
  }

  useEffect(() => {
    const getData = async () => {
      const res = await fetch("https://admin.ogitechsug.com.ng/test/test/");
      const data = await res.json();
      setRammy(data);
      console.log(res, data);
    };

    getData();
  }, []);

  return (
    <>
      <h1>Home Page</h1>
      <Link to="/about">about</Link>

      {rammy &&
        rammy.map((mmy) => (
          <>
            <h1>{mmy.name}</h1>
            <p>{mmy.description}</p>
          </>
        ))}

      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <input type="submit" value={"Send"} />
      </form>

      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header>
      </div>
    </>
  );
}
function About() {
  return (
    <>
      <h1>About Page</h1>
      <Link to="/">back</Link>
    </>
  );
}
export default App;
